<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="10">
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getBookingDates()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="info" block @click="() => createBookingDateModal = { modal: true }">{{ $t('create_booking_date') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <font slot="type" slot-scope="props">
              {{ $_.find(typeOptions, option => { return option.value === props.row.type }).label }}
            </font>
            <font slot="effective_date" slot-scope="props">
              {{ $moment(props.row.effective_date).format('YYYY-MM-DD') }}
            </font>
            <font slot="start_date" slot-scope="props">
              {{ $moment(props.row.start_date).format('YYYY-MM-DD') }}
            </font>
            <font slot="end_date" slot-scope="props">
              {{ $moment(props.row.end_date).format('YYYY-MM-DD') }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup v-if="$moment(props.row.effective_date).isAfter($moment().format('YYYY-MM-DD')) || props.row.type !== 'open'">
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editBookingDateModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
                <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deleteBookingDateModal = { data: $_.extend(props.row, { name: $_.find(typeOptions, option => { return option.value === props.row.type }).label }), modal: true }">{{ $t('delete') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <CreateBookingDateModal :data="createBookingDateModal.data" :show="createBookingDateModal.modal" @showUpdate="show => createBookingDateModal.modal = show" @formSubmit="getBookingDates()" />

      <EditBookingDateModal :data="editBookingDateModal.data" :show="editBookingDateModal.modal" @showUpdate="show => editBookingDateModal.modal = show" @formSubmit="getBookingDates()" />

      <DeleteBookingDateModal :data="deleteBookingDateModal.data" :show="deleteBookingDateModal.modal" @showUpdate="show => deleteBookingDateModal.modal = show" @formSubmit="getBookingDates()" />

    </CCol>
  </CRow>
</template>

<script>
import CreateBookingDateModal from './modal/CreateBookingDateModal'
import EditBookingDateModal from './modal/EditBookingDateModal'
import DeleteBookingDateModal from './modal/DeleteBookingDateModal'

export default {
  name: 'bookingDate',
  components: {
    CreateBookingDateModal,
    EditBookingDateModal,
    DeleteBookingDateModal,
  },
  data () {
    return {
      columns: [ 'effective_date', 'type', 'start_date', 'end_date', 'action' ],
      data: [],
      options: {
        headings: {
          effective_date: this.$t('effective_date'),
          type: this.$t('type'),
          start_date: this.$t('start_date'),
          end_date: this.$t('end_date'),
          action: this.$t('action')
        },
        sortable: [ 'effective_date', 'type', 'start_date', 'end_date' ],
        filterable: [ 'effective_date', 'type', 'start_date', 'end_date' ]
      },
      typeOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('type') + '...' },
        { value: 'open', label: this.$t('open_date') },
        { value: 'close', label: this.$t('close_date') },
        { value: 'full', label: this.$t('full_date') }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: {},
      createBookingDateModal: { data: {}, modal: false },
      editBookingDateModal: { data: {}, modal: false },
      deleteBookingDateModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
    this.getBookingDates()
  },
  methods: {
    getBookingDates () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getBookingDates', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
