<template>
  <CModal
    :title="$t('edit_booking_date')"
    size="xl"
    color="warning"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('effective_date') }}
          </label>
          <div class="col-sm-9">
            <date-picker
              v-model="formData.effective_date"
              :shortcuts="false"
              :editable="false"
              :not-before="$moment()"
              width="100%"
              type="date"
              format="YYYY-MM-DD"
              :input-class="['form-control', 'bg-white', valid_formData_effective_date ? 'is-valid' : 'is-invalid']"
            />
          </div>
        </div>
      </CCol>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('type')"
          :options="typeOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="formData.type"
          :isValid="valid_formData_type"
        />
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('start_date') }}
          </label>
          <div class="col-sm-9">
            <date-picker
              v-model="formData.start_date"
              :shortcuts="false"
              :editable="false"
              :not-before="$moment()"
              width="100%"
              type="date"
              format="YYYY-MM-DD"
              :input-class="['form-control', 'bg-white', valid_formData_start_date ? 'is-valid' : 'is-invalid']"
            />
          </div>
        </div>
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('end_date') }}
          </label>
          <div class="col-sm-9">
            <date-picker
              v-model="formData.end_date"
              :shortcuts="false"
              :editable="false"
              :not-before="$moment()"
              width="100%"
              type="date"
              format="YYYY-MM-DD"
              :input-class="['form-control', 'bg-white', valid_formData_end_date ? 'is-valid' : 'is-invalid']"
            />
          </div>
        </div>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'EditBookingDateModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      typeOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('type') + '...' },
        { value: 'open', label: this.$t('open_date') },
        { value: 'close', label: this.$t('close_date') },
        { value: 'full', label: this.$t('full_date') }
      ],
      formData: {},
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.showBookingDate()
      }
    }
  },
  computed: {
    valid_formData_effective_date () {
      return this.formData.effective_date && this.$moment(this.formData.effective_date).isValid() && this.$moment(this.formData.effective_date).isSameOrAfter(this.$moment().format('YYYY-MM-DD')) ? true : false
    },
    valid_formData_type () {
      return this.formData.type ? true : false
    },
    valid_formData_start_date () {
      return this.formData.start_date && this.$moment(this.formData.start_date).isValid() && this.$moment(this.formData.end_date).isSameOrAfter(this.formData.start_date) ? true : false
    },
    valid_formData_end_date () {
      return this.formData.end_date && this.$moment(this.formData.end_date).isValid() && this.$moment(this.formData.end_date).isSameOrAfter(this.formData.start_date) ? true : false
    },
    valid_submit () {
      return this.valid_formData_effective_date && this.valid_formData_type && this.valid_formData_start_date && this.valid_formData_end_date
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    showBookingDate () {
      const loader = this.$loading.show()
      this.$store.dispatch('showBookingDate', { id: this.modal.data.id }).then(res => {
        this.formData = res[0]
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateBookingDate', this.$_.extend(this.$_.clone(this.formData), { effective_date: this.$moment(this.formData.effective_date).format('YYYY-MM-DD'), start_date: this.$moment(this.formData.start_date).format('YYYY-MM-DD'), end_date: this.$moment(this.formData.end_date).format('YYYY-MM-DD') })).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>
